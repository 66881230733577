<script>
import { mapState, mapMutations } from 'vuex';
import { readFromLocalStorage } from '../../../../stores/cart/utils';
import Dropdown from './Dropdown.vue';


export default {
  data() {
    return {
      showCart: false,
      cartSyncInterval: null,
    };
  },

  computed: {
    ...mapState('cart', ['items']),
  },

  methods: {
    ...mapMutations('cart', ['SET_ITEMS']),

    open() {
      this.showCart = true;
    },

    close() {
      this.showCart = false;
    },

    toggle() {
      this.showCart = !this.showCart;
    },

    syncCartFromStorage() {
      const storedItems = readFromLocalStorage();
      if (JSON.stringify(storedItems) !== JSON.stringify(this.items)) {
        this.SET_ITEMS(storedItems);
      }
      
    },
  },

  mounted() {
    window.addEventListener('storage', this.syncCartFromStorage);

    this.syncCartFromStorage();

    this.cartSyncInterval = setInterval(this.syncCartFromStorage, 1000);
  },

  beforeUnmount() {
    window.removeEventListener('storage', this.syncCartFromStorage);
    clearInterval(this.cartSyncInterval);
  },

  components: { Dropdown },
};
</script>


<template>
  <div class="flex align-center mx-4">
    <!-- <div class="hidden sm:block text-blue-500" @click="toggle"> -->
    <div class="sm:block text-blue-500" @click="toggle">
      <span v-if="items.length > 0">{{ items.length }}</span>
      <i class="fas fa-shopping-cart"></i>
    </div>

    <Dropdown v-if="showCart" @onClose="close" />
  </div>
</template>
